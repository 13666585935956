<template>
  <v-app style="background: #343434">
    <AppBar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";

export default {
  name: "FRANKOF",
  metaInfo: {
    title: "FRANKOF | 1С Warehouse",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "FRANKOF Warehouse",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    AppBar: AppBar,
  },
  created() {
    let vm = this;
    if (this.$vuetify.breakpoint.mobile) {
      this.$store.dispatch("CHANGE_GRID");
    }
    this.$store.dispatch("GET_PRODUCTS");
    setInterval(function () {
      vm.$store.dispatch("GET_PRODUCTS");
    }, 60000);
  },
};
</script>
