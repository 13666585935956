<template>
  <v-card
    tile
    style="position: sticky; top: 85px"
    :color="cardColor"
    dark
    outlined
  >
    <v-card-title class="headline"
      >{{ $t("search") }} <v-spacer></v-spacer
      ><v-btn depressed dark plain @click="reset()"
        >{{ $t("resetfilters") }}
      </v-btn></v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="filter.title"
            dark
            :label="$t('searchbytitle')"
          >
            <template v-slot:prepend-inner>
              <v-scale-transition origin="center center">
                <v-icon
                  class="mr-2"
                  @click="filter.title = ''"
                  v-if="filter.title"
                  >mdi-close</v-icon
                >
                <v-icon class="mr-2" v-else>mdi-magnify</v-icon>
              </v-scale-transition>
            </template>
          </v-text-field>
        </v-col>
        <!-- <v-autocomplete
          v-model="filter.title"
          :items="sorted"
          dark
          clearable
          hide-no-data
          hide-selected
          item-text="products[0].item.title"
          placeholder="Пошук по назві"
        ></v-autocomplete> -->
        <v-col cols="12">
          <!-- <v-autocomplete
        v-show="false"
          v-model="filter.num"
          :items="sorted"
          dark
          clearable
          hide-no-data
          hide-selected
          item-text="order"
          placeholder="Пошук по номеру"
        ></v-autocomplete> -->
          <v-text-field
            v-model="filter.num"
            :items="sorted"
            :rules="[numberRule]"
            dark
            hide-details="auto"
            :label="$t('searchbynum')"
          >
            <template v-slot:prepend-inner>
              <v-scale-transition origin="center center">
                <v-icon class="mr-2" @click="filter.num = ''" v-if="filter.num"
                  >mdi-close</v-icon
                >
                <v-icon class="mr-2" v-else>mdi-pound</v-icon>
              </v-scale-transition>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card dark :color="cardColor" tile outlined>
      <!-- <v-overlay :absolute="true" :value="true" opacity="0.8">
          <v-card-title class="headline"
            ><v-badge overlap dark color="grey darken-3" content="незабаром"
              >Фільтр</v-badge
            ></v-card-title
          >
        </v-overlay> -->

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-chip-group column v-model="selectedCategory">
              <v-chip
                active-class="blue--text text--lighten-2"
                v-for="(chip, i) in chips"
                :key="i"
                dark
                class="my-2 mr-4"
                label
                filter
                filter-icon="mdi-close"
              >
                {{ chip.text }}

                <v-icon right> {{ chip.icon }} </v-icon>
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  name: "FilterPane",
  props: {
    cardColor: String,
  },
  data: function () {
    return {
      selectedCategory: undefined,
      filter: {
        title: "",
        num: "",
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v))) return true;
        return this.$t("hint");
      },
    };
  },
  computed: {
    chips() {
      return _.uniqBy(this.$store.getters.PRODUCTS, "category").map((cat) => {
        if (cat.category == "Ліжка")
          return {
            text: this.$t("beds"),
            filter: "Ліжка",
            icon: "mdi-bed-king-outline",
          };
        else if (cat.category == "Дивани")
          return {
            text: this.$t("sofas"),
            filter: "Дивани",
            icon: "mdi-sofa-outline",
          };
        else if (cat.category == "Модульні системи")
          return {
            text: this.$t("modulars"),
            filter: "Модульні системи",
            icon: "",
          };
        else if (cat.category == "Стільці")
          return {
            text: this.$t("chairs"),
            filter: "Стільці",
            icon: "mdi-sofa-single-outline",
          };
        else if (cat.category == "Аксесуари")
          return { text: this.$t("accesories"), filter: "Аксесуари", icon: "" };
        else if (cat.category.length > 0)
          return { text: cat.category, icon: "" };
        else
          return {
            text: this.$t("withoutcat"),
            icon: "",
          };
      });
    },
    leftovers() {
      let vm = this;
      let products = vm.$store.getters.PRODUCTS;
      if (
        vm.filter.title.length > 0 &&
        vm.filter.num.length > 0 &&
        !isNaN(parseFloat(vm.filter.num))
      ) {
        return products.filter((prod) => {
          return (
            prod.order.includes(vm.filter.num) &&
            prod.products[0].item[vm.$store.getters.LANG]
              .toLowerCase()
              .includes(vm.filter.title.toLowerCase())
          );
        });
      } else if (vm.filter.title.length > 0) {
        return products.filter((prod) => {
          return prod.products[0].item[vm.$store.getters.LANG]
            .toLowerCase()
            .includes(vm.filter.title.toLowerCase());
        });
      } else if (
        vm.filter.num.length > 0 &&
        !isNaN(parseFloat(vm.filter.num))
      ) {
        return products.filter((prod) => {
          return prod.order.includes(vm.filter.num);
        });
      } else {
        return products.filter((prod) => {
          return true;
        });
      }
    },
    sorted() {
      let vm = this;
      let s = vm.leftovers;
      if (vm.selectedCategory >= 0) {
        s = s.filter((prod) => {
          if (vm.chips[vm.selectedCategory].text == vm.$t("withoutcat")) {
            return prod.category == "";
          } else return prod.category == vm.chips[vm.selectedCategory].filter;
        });
      }
      vm.$emit("sortedAndFilteredProds", s);
      return s;
    },
  },

  methods: {
    reset() {
      this.filter.num = "";
      this.filter.title = "";
      this.selectedCategory = undefined;
      this.$store.dispatch("GET_PRODUCTS");
    },
  },
};
</script>

<style>
</style>