import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import i18n from '../main'
axios.defaults.baseURL = "http://64.225.104.49:4444/api"
axios.defaults.timeout = 6000
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    products: [],
    isCardGrid: true,
    lang: localStorage.getItem('lang')? localStorage.getItem('lang'):"uk"
  },
  getters: {
    GRID: state => {
      return state.isCardGrid;
    },
    PRODUCTS: state => {
      return state.products;
    },
    LANG: state => {
      return state.lang;
    }
  },
  mutations: {
    SET_GRID: (state, payload) => {
      state.isCardGrid = payload;
    },
    SET_PRODUCTS: (state, payload) => {
      state.products = payload;
    },
    SET_LANG: (state, payload) => {
      state.lang = payload;
      localStorage.setItem('lang', payload);
    },
  },
  actions: {
    CHANGE_GRID: async ({commit,state}) =>{
      commit('SET_GRID', !state.isCardGrid );
    },
    CHANGE_LANG: async ({ commit, state }, lang) => {
      
      commit('SET_LANG', lang);
      
    },
    GET_PRODUCTS: async ({commit,state}) => {
      let {data} = await axios.get("/file/v2");
      // let test = _.sortBy(_.values(_.groupBy(_.compact(data.content.map((pr)=>{
      //   if(pr.products){
      //     return {...pr}
      //   }
      // })), 'order')), [function(o) {return o[0].products[0].title; }])
      // //let test = _.groupBy(data.content, 'order')
      // console.log("TTT",test)
      //console.log(data.content);
      _.forEach(data.content, function(value, key) {
        //console.log(value.products[0].item.title);
       
        //console.log(i18n.t(value))
        data.content[key].products[0].item.uk = data.content[key].products[0].item.title;
        data.content[key].products[0].item.en = _.map(data.content[key].products[0].item.title.split(" "), function (v, k) {
          return i18n.t(v.toLowerCase()).toString();
        }).join(" ")

      });
       //console.log(data.content);
      commit('SET_PRODUCTS', data.content);
    },
  },
  modules: {
  }
})
