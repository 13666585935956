<template>
  <v-col class="mt-2" cols="12">
    <v-data-table
      dark
      :items="products"
      :headers="headers"
      hide-default-footer
      disable-pagination
      fixed-header
    >
      <template v-slot:[`item.products[0].item`]="{ item }">
        <span class="subtitle-1">
          {{
            item.products.length == 1
              ? item.products[0].item[$store.getters.LANG]
              : item.category == "Модульні системи"
              ? item.products[0].item[$store.getters.LANG].split(" ")[0]
              : item.products[0].item[$store.getters.LANG]
          }}

          {{
            item.products.length > 1 || item.products[0].item.comment
              ? commentDecoder(item)
              : ""
          }}
        </span>
      </template>
      <template v-slot:item.photo="{ item }">
        <v-avatar v-if="item.photo" class="my-1" color="grey" size="100" tile>
          <v-img
            :lazy-src="photoDecoder(item.photo)"
            :src="photoDecoder(item.photo)"
          ></v-img>
        </v-avatar>
        <span v-else>-</span>
      </template>
      <template v-slot:item.products[0].description="{ item }">
        <span v-if="item.products[0].item.corner"
          >Кут: {{ item.products[0].item.corner }}<br
        /></span>
        <span v-if="item.products[0].description.length == 1">
          <span v-for="(spec, isp) in item.products[0].description" :key="isp">
            {{ $t(spec.type) }}: {{ spec.textile }}
            <b>| {{ spec.manufacturer }}</b
            ><br />
          </span>
        </span>
        <span v-else>
          <span v-for="(spec, isp) in item.products[0].description" :key="isp">
            {{ $t(spec.type) }}: {{ spec.textile }}
            <b>| {{ spec.manufacturer }}</b
            ><br />
          </span>
        </span>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    products: Array,
  },
  methods: {
    commentDecoder(prod) {
      let vm = this;
      console.log(prod);
      let arr = prod.products.filter((e) => {
        if (e.item.comment.length > 0 && e.item.comment != (undefined || null))
          return true;
      })[0];
      if (vm.$store.getters.LANG != "uk") {
        let tmp = !_.isEmpty(arr) ? arr.item.comment.split(/[ +]+/) : "";
        // console.log(tmp);
        if (!_.isEmpty(arr)) {
          arr.item.comment = _.trim(
            _.map(tmp, function (com) {
              return vm
                .$t(
                  _.trim(
                    com.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                  ).toLowerCase()
                )
                .toUpperCase();
            }).join("+"),
            "+"
          );
        }
      }

      return _.isEmpty(arr) ? "" : " | " + arr.item.comment;
    },
    photoDecoder(link) {
 return link;
      }
    },
  },
  data: function () {
    return {
      filter: {
        title: "",
        num: "",
      },
      footer: { options: { itemsPerPage: -1 } },
      headers: [
        {
          text: this.$t("title"),
          align: "start",
          sortable: true,
          value: "products[0].item",
        },
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "order",
        },
        {
          text: this.$t("specs"),
          align: "start",
          sortable: false,
          value: "products[0].description",
        },
        {
          text: this.$t("qty"),
          align: "start",
          sortable: true,
          value: "qty",
        },
        {
          text: this.$t("photo"),
          sortable: false,
          value: "photo",
        },
      ],
    };
  },
};
</script>

<style>
.tt {
  background-color: azure;
}
</style>