<template>
  <v-container>
    <v-row v-if="$store.getters.GRID">
      <v-col class="mt-2" md="4" sm="12" cols="12">
      <FilterPane @sortedAndFilteredProds="filteredProducts" cardColor="#424242" />
      </v-col>
      <CardGrid :products="products" />
    </v-row>
    <v-row v-else>
      <v-col class="mt-2" cols="12">
      <FilterPane @sortedAndFilteredProds="filteredProducts" />
      </v-col>
      <TableGrid :products="products" />
      <!-- <BottomBar @sortedAndFiltered="filteredProductsMobile" :products="$store.getters.PRODUCTS"/> -->
    </v-row>
    <v-slide-x-reverse-transition>
      <v-btn
        v-if="scrolled && $vuetify.breakpoint.smAndUp && $store.getters.GRID"
        @click="
          $vuetify.goTo(0, {
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic',
          })
        "
        fab
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-chevron-up</v-icon></v-btn
      >
    </v-slide-x-reverse-transition>
  </v-container>
</template>

<script>
import Filter from "./Filter";
import CardGrid from "./CardGrid";
import TableGrid from "./ProductTable"
import BottomBar from "./BottomBar"

export default {
  data: () => ({
    scrolled: false,
    products: [],
    productsMobile: []
  }),
  components: {
    "FilterPane": Filter,
    "CardGrid": CardGrid,
    "TableGrid": TableGrid,
    "BottomBar": BottomBar
  },
  created: function () {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 250;
    },
    filteredProducts(products) {
      this.products = products;
    },
    filteredProductsMobile(products){
      this.productsMobile = products;
    }
  }
};
</script>

<style>
</style>