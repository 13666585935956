<template>
  <v-container>
    <v-row>
      <v-col
        class="mt-2"
        v-if="$vuetify.breakpoint.mdAndUp"
        md="5"
        sm="12"
        cols="12"
      >
        <v-card
          tile
          style="position: sticky; top: 85px"
          color="#424242"
          dark
          outlined
        >
          <v-card-title class="headline"
            >Пошук <v-spacer></v-spacer
            ><v-btn depressed dark plain @click="reset()"
              >Скинути</v-btn
            ></v-card-title
          >
          <v-card-text>
            <v-autocomplete
              v-model="filter.title"
              :items="leftovers"
              dark
              clearable
              hide-no-data
              hide-selected
              @change="filterByTitle()"
              item-text="products[0].title"
              placeholder="Пошук по назві"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filter.num"
              :items="leftovers"
              dark
              clearable
              hide-no-data
              hide-selected
              item-text="order"
              @change="filterByNum()"
              placeholder="Пошук по номеру"
            ></v-autocomplete>
          </v-card-text>
          <!-- <v-divider></v-divider> -->
          <v-card tile outlined>
            <v-overlay :absolute="true" :value="true" opacity="0.8">
              <v-card-title class="headline"
                ><v-badge overlap dark color="grey darken-3" content="незабаром"
                  >Фільтр</v-badge
                ></v-card-title
              >
            </v-overlay>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-chip
                    disabled
                    dark
                    class="ma-2"
                    label
                    filter
                    filter-icon="mdi-close"
                  >
                    <v-icon left> mdi-sofa-outline </v-icon>
                    Дивани
                  </v-chip>
                  <v-chip
                    disabled
                    dark
                    class="ma-2"
                    label
                    filter
                    filter-icon="mdi-close"
                  >
                    <v-icon left> mdi-sofa-single-outline </v-icon>
                    Крісла
                  </v-chip>
                  <v-chip
                    disabled
                    dark
                    class="ma-2"
                    label
                    filter
                    filter-icon="mdi-close"
                  >
                    <v-icon left> mdi-bed-king-outline </v-icon>
                    Ліжка
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>

      <v-col class="mt-2" md="7" sm="12" cols="12">
        <v-row v-if="$store.getters.GRID">
          <v-col
            cols="12"
            sm="6"
            v-if="product.products"
            v-for="(product, i) in sorted"
            :key="i"
          >
            <v-card
              tile
              outlined
              style="overflow-y: auto"
              height="100%"
              color="#424242"
              dark
            >
              <v-card-title class="text-h5">
                {{ product.products ? product.products[0].title : "" }}
                <v-spacer></v-spacer>
                <span class="grey--text text-subtitle-1"
                  >#{{ product.order }}</span
                >
              </v-card-title>
              <v-img
                contain
                lazy-src="https://picsum.photos/id/11/10/6"
                src="https://picsum.photos/id/11/500/300"
              >
              </v-img>

              <v-card-subtitle>
                <!-- {{ product.products ? product.products[0].category : "" }} -->
                Кількість: {{ product.qty }}
                <br />
                <!-- {{product.products?product.products[0].specification:""}} -->
                <span v-if="product.products[0].specification">
                  <span
                    v-for="(
                      spec, isp
                    ) in product.products[0].specification.split(';')"
                    :key="isp"
                  >
                    {{ spec }}<br />
                  </span>
                </span>
              </v-card-subtitle>

              <v-card-actions>
                <!-- <v-btn
          plain
          color="green"
          @click="addToCart(i)"
        >
          Замовити
        </v-btn> -->
                <v-spacer></v-spacer>
              </v-card-actions>

              <!-- <v-expand-transition>
        <v-card dark
          v-if="product.reveal"
          color="#424242"
          class="transition-fast-in-fast-out v-card--reveal"
          style="height: 100%;"
        >
          <v-card-text class="pb-0">
            <p class="display-1">
              Додати в корзину  {{product.order}}?
            </p>
           
          </v-card-text>
          <v-card-actions>
              <v-btn
              @click="product.reveal=false"
              plain
              color="teal"
            >
            Так
            </v-btn>
            <br>
            <v-btn
            @click="product.reveal=false"
              plain
              color="red"
            >
            Ні
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition> -->
            </v-card>
          </v-col>
        </v-row>
        <ProductTable :productsIn="sorted" v-else/>
      </v-col>
    </v-row>
    <!-- <v-slide-x-reverse-transition > -->
    <!-- <v-speed-dial 
        v-model="fab"
        fixed
        bottom
        right
        
        direction="top"
        
        transition='slide-y-reverse-transition'
      >
        <template v-slot:activator>
          <v-slide-x-reverse-transition >
          <v-btn
            
            v-model="fab"
            
            fab 
            dark
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
          </v-slide-x-reverse-transition >
        </template>
        <v-btn
          v-if="scrolled"
          fab
          dark
          @click="$vuetify.goTo(0, {
        duration: 500,
      offset: 0,
      easing: 'easeInOutCubic',
      })"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn
          
          fab
          small
          dark
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        
      </v-speed-dial> -->
    <!-- </v-slide-x-reverse-transition> -->
    <v-slide-x-reverse-transition>
      <v-btn v-if="$vuetify.breakpoint.smAndDown" fab fixed dark bottom right>
        <v-icon>mdi-filter</v-icon></v-btn
      >
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <v-btn
        v-if="scrolled && $vuetify.breakpoint.smAndUp"
        @click="
          $vuetify.goTo(0, {
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic',
          })
        "
        fab
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-chevron-up</v-icon></v-btn
      >
    </v-slide-x-reverse-transition>
  </v-container>
</template>

<script>
import _ from "lodash";
import ProductTable from "./ProductTable"

export default {
  data: () => ({
    lefts: [],
    raw: [],
    fab: false,
    sheet: true,
    scrolled: false,
    filter: {
      title: "",
      num: "",
    },
  }),
  components:{
    "ProductTable":ProductTable
  },

  created: function () {
    let vm = this;
    window.addEventListener("scroll", this.handleScroll);
    vm.$axios.get("/file").then(function (response) {
      vm.raw = response.data.content;
      vm.lefts = vm.raw;
      // console.log(vm.raw);
      //vm.lefts = response.data.content.map(obj=> ({ ...obj, reveal: false }))
    });
  },
  computed: {
    leftovers() {
      let vm = this;
      if (vm.filter.title && vm.filter.num) {
        return vm.lefts.filter((prod) => {
          if (prod.products) {
            return (
              prod.order == vm.filter.num &&
              prod.products[0].title == vm.filter.title
            );
          }
        });
      } else if (vm.filter.title) {
        return vm.lefts.filter((prod) => {
          if (prod.products) {
            return prod.products[0].title == vm.filter.title;
          }
        });
      } else if (vm.filter.num) {
        return vm.lefts.filter((prod) => {
          if (prod.products) {
            return prod.order == vm.filter.num;
          }
        });
      } else {
        return vm.lefts.filter((prod) => {
          if (prod.products) {
            return true;
          }
        });
      }

      // return vm.lefts.filter((prod)=>{
      //   // console.log(prod)
      //   if (prod.products) {
      //     //console.log(prod.order == vm.filter.num && prod.products[0].title == vm.filter.title)
      //     return (prod.order == vm.filter.num || prod.products[0].title == vm.filter.title);
      //   }
      //   //return prod
      // })
    },
    sorted() {
      return _.sortBy(this.leftovers, ["products[0].title"]);
    },
    items() {
      let vm = this;
      let tmp_arr = [];
      _.each(vm.lefts, function (item) {
        if (item.products) {
          tmp_arr.push({ order: item.order, product: item.products[0].title });
        }
      });

      return tmp_arr.filter((prod) => {
        if (prod.products) {
          return prod.order == vm.filter.num && prod.product == vm.filter.title;
        }
      });
    },
  },

  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 250;
    },
    filterByTitle() {
      //       if(this.filter.title){
      // this.lefts = this.lefts.filter((prod) => {
      //           if (prod.products) {
      //             return prod.products[0].title == this.filter.title;
      //           }
      //         });
      //       }
      //console.log("FILTER", title)
      // this.lefts = this.lefts.filter(prod=>{
      //   if(prod.products){ return prod.products[0].title==title}
      // })
    },
    filterByNum() {
      // if(this.filter.num){
      // this.lefts = this.lefts.filter((prod) => {
      //     if (prod.products) {
      //       return prod.order == this.filter.num;
      //     }
      //   });
      //   }
      //console.log("FILTER", title)
      // this.lefts = this.lefts.filter(prod=>{
      //   if(prod.products){ return prod.products[0].title==title}
      // })
    },
    reset() {
      //
      this.lefts = this.raw;
      this.filter.num = "";
      this.filter.title = "";

      // console.log("RAW", this.raw, "LFT", this.lefts)
    },
  },
};
</script>
