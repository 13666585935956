import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../components/HelloWorld.vue'
import Leftovers from '../components/Leftovers.vue'
import View from '../components/ProductView.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
      path: '/',
      name: 'Home',
      component: View
    },
  {
    path: '/lefts',
    name: 'Leftovers',
    component: Leftovers
  }
]

const router = new VueRouter({
  routes
})

export default router
