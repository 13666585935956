import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import uk from './translations/uk.json'
import en from './translations/en.json'

Vue.use(VueI18n);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

const messages = {
  en,
  uk
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang')? localStorage.getItem('lang'):"uk", // set locale
  messages, // set locale messages
})



new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export default i18n;