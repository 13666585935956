import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import uk from 'vuetify/lib/locale/uk';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#00a86b'
      },
    },
  },
    lang: {
      locales: { uk },
      current: 'uk',
    },
});
