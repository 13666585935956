<template>
  <v-col class="mt-2" md="8" sm="12" cols="12">
    <v-row>
      <v-col cols="12" sm="6" v-for="(product, i) in products" :key="i">
        <v-card
          tile
          outlined
          style="overflow-y: auto"
          height="100%"
          color="#424242"
          dark
        >
          <v-card-title class="text-h6">
            {{
              product.products.length == 1
                ? product.products[0].item[$store.getters.LANG]
                : product.category == "Модульні системи"
                ? product.products[0].item[$store.getters.LANG].split(" ")[0]
                : product.products[0].item[$store.getters.LANG]
            }}

            <!-- {{
              product.products.length == 1
                ? titleTranslater(product.products[0].item.title)
                : product.category == "Модульні системи"
                ? product.products[0].item.title.split(" ")[0]
                : product.products[0].item.title
            }} -->
            <!-- <i18n> -->
            {{
              product.products.length > 1 || product.products[0].item.comment
                ? commentDecoder(product.products)
                : ""
            }}
            <!-- </i18n> -->

            <v-spacer></v-spacer>
            <span class="grey--text text-subtitle-1">#{{ product.order }}</span>
          </v-card-title>

          <v-divider></v-divider>
          <v-img
            v-if="product.photo"
            contain
            :lazy-src="photoDecoder(product.photo)"
            :src="photoDecoder(product.photo)"
          >
          </v-img>

          <v-card-subtitle>
            <!-- {{ product.category ? product.category : "" }}<br /> -->
            {{ $t("quantity") }}: {{ product.qty }}

            <span v-if="product.products[0].item.corner"
              ><br />{{ $t("corner") }}:
              {{ product.products[0].item.corner }}</span
            >
            <br />
            <span v-if="product.products[0].description.length == 1">
              <span
                v-for="(spec, isp) in product.products[0].description"
                :key="isp"
              >
                {{ $t(spec.type) }}: {{ spec.textile }}
                <b>| {{ spec.manufacturer }}</b
                ><br />
              </span>
            </span>
            <span v-else>
              <span
                v-for="(spec, isp) in product.products[0].description"
                :key="isp"
              >
                {{ $t(spec.type) }}: {{ spec.textile }}
                <b>| {{ spec.manufacturer }}</b
                ><br />
              </span>
            </span>
          </v-card-subtitle>

          <v-card-actions>
            <!-- <v-btn
          plain
          color="green"
          @click="addToCart(i)"
        >
          Замовити
        </v-btn> -->
            <v-spacer></v-spacer>
          </v-card-actions>

          <!-- <v-expand-transition>
        <v-card dark
          v-if="product.reveal"
          color="#424242"
          class="transition-fast-in-fast-out v-card--reveal"
          style="height: 100%;"
        >
          <v-card-text class="pb-0">
            <p class="display-1">
              Додати в корзину  {{product.order}}?
            </p>
           
          </v-card-text>
          <v-card-actions>
              <v-btn
              @click="product.reveal=false"
              plain
              color="teal"
            >
            Так
            </v-btn>
            <br>
            <v-btn
            @click="product.reveal=false"
              plain
              color="red"
            >
            Ні
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition> -->
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    products: Array,
  },
  methods: {
    commentDecoder(prod) {
      let vm = this;
      let arr = prod.filter((e) => {
        if (e.item.comment.length > 0 && e.item.comment != (undefined || null))
          return true;
      })[0];
      if (vm.$store.getters.LANG != "uk") {
        let tmp = !_.isEmpty(arr) ? arr.item.comment.split(/[ +]+/) : "";
        // console.log(tmp);
        if (!_.isEmpty(arr)) {
          arr.item.comment = _.trim(
            _.map(tmp, function (com) {
              return vm
                .$t(
                  _.trim(
                    com.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                  ).toLowerCase()
                )
                .toUpperCase();
            }).join("+"),
            "+"
          );
        }
      }

      return _.isEmpty(arr) ? "" : " | " + arr.item.comment;
    },
    photoDecoder(link) {
      let part = link.split("/file/d/")[1];

      if (part) {
        part = part.split("/")[0];
        return "https://drive.google.com/uc?export=view&id=" + part;
      } else {
        return link;
      }
    },
    titleTranslater(title) {
      let spl = title.split(" ");
      //console.log(this.$t(spl[0]));
      return this.$t(spl[0]);
    },
  },
};
</script>

<style>
</style>