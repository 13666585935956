<template>
  <v-app-bar elevation="0" color="#424242" fixed app>
    <v-toolbar-title>
      <v-img :src="require('../assets/logo.svg')" contain width="50%"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn-toggle
      @change="toggleGrid()"
      :value="$store.getters.GRID ? 0 : 1"
      dense
      mandatory
      dark
      tile
    >
      <v-btn>
        <v-icon>mdi-view-grid-outline</v-icon>
      </v-btn>

      <v-btn>
        <v-icon>mdi-table-large</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-menu dark rounded="0" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed class="ml-3" tile dark v-bind="attrs" v-on="on">
          {{ $store.getters.LANG }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in Object.keys($i18n.messages)"
          :key="index"
          @click="changeLocale(item)"
        >
          <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    toggleGrid() {
      this.$store.dispatch("CHANGE_GRID");
      this.$store.dispatch("GET_PRODUCTS");
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("CHANGE_LANG", lang);
    },
  },
};
</script>

<style></style>
