<template>
  <v-col cols="12">
    <v-bottom-navigation background-color="#424242" app fixed grow dark>
      <v-menu max-height="500px" top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <span>Назва</span>
            <v-icon>mdi-format-title</v-icon></v-btn
          >
        </template>
        <v-list dark color="#424242">
          <v-list-item-group
            v-model="filter.byTitle"
            color="blue"
            @change="onGroupChange()"
          >
            <v-list-item v-for="(item, index) in distinctTitle" :key="index">
              <v-list-item-content>
                <!-- <v-list-item-title v-if="item.products.length==1">{{
                  item.products[0].item.title
                }}</v-list-item-title>
                <v-list-item-title v-else>{{
                  item.products[0].item.title.split(' ')[0]
                }}</v-list-item-title> -->
                <v-list-item-title>{{
                  item.products[0].item.title
                }}</v-list-item-title>
               
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu max-height="500px" top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <span>Номер</span>
            <v-icon>mdi-pound</v-icon></v-btn
          >
        </template>
        <v-list dark color="#424242">
          <v-list-item-group
            v-model="filter.byNum"
            color="blue"
            @change="onGroupChange()"
          >
            <v-list-item v-for="(item, index) in distinctNums" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ item.order }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-bottom-navigation>
  </v-col>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    products: Array,
  },
  data: () => ({
    filter: {
      byTitle: null,
      byNum: null,
    },
  }),
  computed: {
    distinctTitle() {
      return _.uniqBy(this.products, "products[0].item.title");
    },
    distinctNums() {
      return _.sortBy(_.uniqBy(this.products, "order"),"order");
    },
  },
  watch: {
    products: function (val) {
      this.$emit("sortedAndFiltered", val);
    },
  },
  methods: {
    onGroupChange() {
      let vm = this;
      if (
        vm.filter.byTitle >= 0 &&
        vm.filter.byNum >= 0 &&
        vm.filter.byTitle != undefined &&
        vm.filter.byNum != undefined &&
        vm.filter.byTitle != null &&
        vm.filter.byNum != null
      ) {
        
        let s = vm.products.filter((prod) => {
          return (
            prod.products[0].item.title ==
              vm.distinctTitle[vm.filter.byTitle].products[0].item.title &&
            prod.order == vm.distinctNums[vm.filter.byNum].order
          );
        });
        vm.$emit("sortedAndFiltered", s);
      } else if (
        vm.filter.byTitle >= 0 &&
        vm.filter.byTitle != undefined &&
        vm.filter.byTitle != null
      ) {
        
        let v = vm.products.filter((prod) => {
          return (
            prod.products[0].item.title ==
            vm.distinctTitle[vm.filter.byTitle].products[0].item.title
          );
        });

        vm.$emit("sortedAndFiltered", v);
      } else if (
        vm.filter.byNum >= 0 &&
        vm.filter.byNum != undefined &&
        vm.filter.byNum != undefined &&
        vm.filter.byNum != null
      ) {
        

        let v = vm.products.filter((prod) => {
          return prod.order == vm.distinctNums[vm.filter.byNum].order;
        });
        vm.$emit("sortedAndFiltered", v);
      } else {
        let v = vm.products.filter((prod) => {
          return true;
        });
        vm.$emit("sortedAndFiltered", v);
      }
    },
  },
};
</script>

<style>
</style>